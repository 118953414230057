<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton
          btnTitle="Add New"
          :showBtn="false"
          :showSettingBtn="true"
          :showAddNew="true"
          title="Quotation List"
          @onClickAddNewButton="onClickAddNewButton"
          @onClickSettingButton="onClickSettingButton"
      />
      <DateQuerySetter @onClickGo="getJournals">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Supplier</label>
          <input class="form-control" type="text" name="supplier" v-model="supplier">
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Requester</label>
          <input class="form-control" type="text" name="requester" v-model="requester">
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Project</label>
          <input class="form-control" type="text" name="project" v-model="project">
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Status</label>
          <input class="form-control" type="text" name="status" v-model="status">
        </div>
      </DateQuerySetter>

      <div class="row">
        <div class="col-12 float-left">
          <label for="colFormLabel" class="col-form-label">Quick Search</label>
          <input class="form-control" type="text" name="status" v-model="quick_search">
        </div>
      </div>
    </div>

    <ListTable/>
    <div class="mb-2"></div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination
          :offset="offset"
          @onPageChange="onPageChange"
          ref="pagination"
      />
    </div>
  </div>
</template>

<script setup>
import TitleButton     from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import ListTable       from '@/components/molecule/procurement/vendor-selection/single-vendor/QuotationListingTable.vue'
import Pagination      from '@/components/atom/Pagination'
import Loader          from '@/components/atom/LoaderComponent'
import {inject}        from "vue";

function onClickAddNewButton() {
  alert('Add New Button clicked');
}

function onClickSettingButton() {
  alert('Setting Button clicked');
}

function getJournals() {
  //todo
}

function onPageChange() {
  //todo
}
</script>
